import { useState, useEffect } from 'react';
import { Product, NotionProduct } from '../types/notion';

// API URL basierend auf der Umgebung
const API_URL = import.meta.env.DEV ? 'http://localhost:9999/.netlify/functions' : '/.netlify/functions';

export interface SelectedProducts {
  [key: string]: {
    selected: boolean;
    price?: number;
    note?: string;
  };
}

const mapNotionToProduct = (notionProduct: NotionProduct): Product => {
  return {
    id: notionProduct.id,
    name: notionProduct.properties.Name.title[0]?.plain_text || '',
    description: notionProduct.properties.Beschreibung.rich_text[0]?.plain_text || '',
    price: notionProduct.properties.Preis.number || 0,
    category: notionProduct.properties.Kategorie.select?.name || '',
    hint: notionProduct.properties.Hinweis.rich_text[0]?.plain_text || '',
    period: notionProduct.properties.Periode.select?.name || '',
  };
};

export function useProducts() {
  const [products, setProducts] = useState<Product[]>([]);
  const [customProducts, setCustomProducts] = useState<Product[]>(() => {
    const saved = localStorage.getItem('customProducts');
    return saved ? JSON.parse(saved) : [];
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedProducts, setSelectedProducts] = useState<SelectedProducts>({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${API_URL}/getProducts`);
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        const mappedProducts = data.results
          .filter((p: NotionProduct) => p.properties.Name.title.length > 0)
          .map(mapNotionToProduct);
        
        setProducts(mappedProducts);
        setError(null);
      } catch (err) {
        console.error('Error fetching products:', err);
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const addCustomProduct = (product: Product) => {
    console.log('useProducts: Füge Custom Product zum State hinzu:', product);
    setCustomProducts(prev => {
      const newProducts = [...prev, product];
      console.log('useProducts: Neuer customProducts State:', newProducts);
      localStorage.setItem('customProducts', JSON.stringify(newProducts));
      return newProducts;
    });
  };

  const removeCustomProduct = (productId: string) => {
    setCustomProducts(prev => {
      const newProducts = prev.filter(p => p.id !== productId);
      localStorage.setItem('customProducts', JSON.stringify(newProducts));
      return newProducts;
    });
    // Wenn das Produkt ausgewählt war, entferne es auch aus der Auswahl
    if (selectedProducts[productId]) {
      const { [productId]: _, ...rest } = selectedProducts;
      setSelectedProducts(rest);
    }
  };

  const toggleProduct = (productId: string) => {
    setSelectedProducts(prev => {
      const current = prev[productId] || { selected: false };
      return {
        ...prev,
        [productId]: {
          ...current,
          selected: !current.selected
        }
      };
    });
  };

  const updateProductPrice = (productId: string, price: number) => {
    setSelectedProducts(prev => ({
      ...prev,
      [productId]: {
        ...(prev[productId] || { selected: false }),
        price
      }
    }));
  };

  const updateProductNote = (productId: string, note: string) => {
    setSelectedProducts(prev => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        note
      }
    }));
  };

  const getTotalPrice = (customPrices: Record<string, number> = {}) => {
    const allProducts = [...products, ...customProducts];
    return allProducts.reduce((sum, product) => {
      if (!selectedProducts[product.id]?.selected) return sum;
      const price = customPrices[product.id] ?? product.price ?? selectedProducts[product.id]?.price ?? 0;
      return sum + price;
    }, 0);
  };

  return {
    products: (() => {
      const allProducts = [...products, ...customProducts];
      //console.log('useProducts - customProducts:', customProducts);
      //console.log('useProducts - allProducts:', allProducts);
      return allProducts;
    })(),
    loading,
    error,
    selectedProducts,
    toggleProduct,
    getTotalPrice,
    addCustomProduct,
    removeCustomProduct,
    updateProductPrice,
    updateProductNote,
    customProducts
  };
}
