import React from 'react';
import { Customer } from '../types/notion';
import { useState, useEffect } from 'react';

const API_URL = import.meta.env.DEV ? 'http://localhost:9999/.netlify/functions' : '/.netlify/functions';

export interface NotionCustomerE {
      id: string;
      company: string;
      contactperson: string;
      street: string;
      zip: string;
      city: string;
      email: string;
}

interface NotionCustomer {
  id: string;
  properties: {
    Name: {
      title: { plain_text: string }[];
    };
    Kontakt?: {
      relation: { id: string }[]; // Assuming `relation` is an array of objects with `id`
    };
    Straße: {
      rich_text: { plain_text: string }[];
    };
    PLZ: {
      rich_text: { plain_text: string }[];
    };
    Ort: {
      rich_text: { plain_text: string }[];
    };
    "E-Mail für Angebot"?: {
      email?: string;
    };
  };
}

const mapNotionToCustomer = (notionCustomer: NotionCustomer) => {
  return {
    id: notionCustomer.id,
    company: notionCustomer.properties.Name.title[0]?.plain_text || '',
    contactperson: "",
    street: notionCustomer.properties.Straße.rich_text[0]?.plain_text || '',
    zip: notionCustomer.properties.PLZ.rich_text[0]?.plain_text || '',
    city: notionCustomer.properties.Ort.rich_text[0]?.plain_text || '',
    email: notionCustomer.properties["E-Mail für Angebot"]?.email || '',
    //name: notionProduct.properties.Name.title[0]?.plain_text || '',
    //description: notionProduct.properties.Beschreibung.rich_text[0]?.plain_text || '',
    //price: notionProduct.properties.Preis.number || 0,
    //category: notionProduct.properties.Kategorie.select?.name || '',
    //hint: notionProduct.properties.Hinweis.rich_text[0]?.plain_text || '',
    //period: notionProduct.properties.Periode.select?.name || '',
  };
};

interface CustomerFormProps {
  className?: string;
  customer: Customer;
  onCustomerChange: (customer: Customer) => void;
}

export const CustomerForm: React.FC<CustomerFormProps> = (

{
  className = '',
  customer,
  onCustomerChange,
}) => {



    const [isCustomerSearchModalOpen, setCustomerSearchModalOpen] = useState(false);


 const toggleCustomerSearchModal = () => {
     setCustomerSearchModalOpen(!isCustomerSearchModalOpen);
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    onCustomerChange({
      ...customer,
      [id]: value,
    });
  };

    /*const selectCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.clear();
        console.log(e);
        setCustomerSearchModalOpen(!isCustomerSearchModalOpen);
    };*/

const selectCompany = (event: React.MouseEvent<HTMLButtonElement>) => {
  const target = event.target as HTMLButtonElement;




  onCustomerChange({
    ...customer,
    firma: target.dataset.company || customer.firma,
    ansprechpartner: target.dataset.contactperson || customer.ansprechpartner,
    strasse: target.dataset.street || customer.strasse,
    plz: target.dataset.zip || customer.plz,
    ort: target.dataset.city || customer.ort,
    email: target.dataset.email || customer.email,
  });
   setCustomerSearchModalOpen(!isCustomerSearchModalOpen);
};

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const [customerslist, setCustomerslist] = useState<NotionCustomerE[]>([]); // Correct type and default to an empty array

    // Update the `fetchProducts` function to properly type `data.results`
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const response = await fetch(`${API_URL}/getCustomers`);
          if (!response.ok) {
            throw new Error('Failed to fetch customers');
          }
          const data = await response.json();
          const mappedProducts = data.results.map(mapNotionToCustomer);

          setCustomerslist(mappedProducts);
          setError(null);
        } catch (err) {
          console.error('Error fetching customers:', err);
          setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
          setLoading(false);
        }
      };

      fetchProducts();
    }, []);



/*
if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 p-4">
        {error}
      </div>
    );
  }
  */

  return (
    <div className={`bg-white rounded-lg p-6 shadow-sm ${className}`}>

    <div className="flex justify-between items-center">
     <h2 className="text-xl font-semibold mb-6 text-gray-900">Kunde</h2>

     {!loading && !error && (
     <button type="button" onClick={toggleCustomerSearchModal} className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mb-6">Kunden-Datenbank</button>
     )}
     </div>

        {isCustomerSearchModalOpen && (
        <div className="space-y-6 customerModal">
         <button type="button" onClick={toggleCustomerSearchModal} className="customerModalClose text-gray-700">X</button>
          <div className="grid grid-cols-6 gap-8 font-semibold text-gray-700">
            <div>Firma</div>
            <div>Ansprechpartner</div>
            <div>Straße</div>
            <div>PLZ</div>
            <div>Ort</div>
            <div>E-Mail</div>
          </div>

              {customerslist.length > 0 &&
                customerslist.map((customer: NotionCustomerE) => (
<div className="grid grid-cols-6 gap-8 text-gray-700" key={customer.email}>
      <div>
        <button
          className="bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 d-inline p-1"
          onClick={selectCompany}
          data-company={customer.company}
          data-contactperson={customer.contactperson}
          data-street={customer.street}
          data-zip={customer.zip}
          data-city={customer.city}
          data-email={customer.email}
        >
          +
        </button>
        &nbsp;{customer.company}
      </div>
      <div>{customer.contactperson}</div>
      <div>{customer.street}</div>
      <div>{customer.zip}</div>
      <div>{customer.city}</div>
      <div>{customer.email}</div>
    </div>
                ))}


        </div>
      )}


      <div className="space-y-6">
        <div className="grid grid-cols-2 gap-8">
          <div>
            <label htmlFor="firma" className="block text-sm font-normal text-gray-600 mb-2">
              Firma
            </label>
            <input
              type="text"
              id="firma"
              value={customer.firma}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
              placeholder="Firmenname"
            />
          </div>
          <div>
            <label htmlFor="ansprechpartner" className="block text-sm font-normal text-gray-600 mb-2">
              Ansprechpartner
            </label>
            <input
              type="text"
              id="ansprechpartner"
              value={customer.ansprechpartner}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
              placeholder="Name des Ansprechpartners"
            />
          </div>
        </div>

        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-6">
            <label htmlFor="strasse" className="block text-sm font-normal text-gray-600 mb-2">
              Straße
            </label>
            <input
              type="text"
              id="strasse"
              value={customer.strasse}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
              placeholder="Straße und Hausnummer"
            />
          </div>
          <div className="col-span-3">
            <label htmlFor="plz" className="block text-sm font-normal text-gray-600 mb-2">
              PLZ
            </label>
            <input
              type="text"
              id="plz"
              value={customer.plz}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
              placeholder="PLZ"
            />
          </div>
          <div className="col-span-3">
            <label htmlFor="ort" className="block text-sm font-normal text-gray-600 mb-2">
              Ort
            </label>
            <input
              type="text"
              id="ort"
              value={customer.ort}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
              placeholder="Ort"
            />
          </div>
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-normal text-gray-600 mb-2">
            E-Mail
          </label>
          <input
            type="email"
            id="email"
            value={customer.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
            placeholder="E-Mail-Adresse"
          />
        </div>
      </div>
    </div>
  );
};
