import React from 'react';
import { Eye, FileDown, Save } from 'lucide-react';

interface TotalBarProps {
  totals: {
    einmalig: number;
    monatlich: number;
    quartalsweise: number;
  };
  onPreview?: () => void;
  onExportPDF?: () => void;
  onSaveQuote?: () => void;
}

export const TotalBar: React.FC<TotalBarProps> = ({
  totals,
  onPreview,
  onExportPDF,
  onSaveQuote,
}) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex gap-8">

            {totals.einmalig > 0 && (
              <div>
                <div className="text-sm font-medium text-gray-600">Einmalig</div>
                <div className="text-2xl font-bold text-green-700">{totals.einmalig.toFixed(2)} €</div>
              </div>
            )}
            {totals.monatlich > 0 && (
              <div>
                <div className="text-sm font-medium text-gray-600">Monatlich</div>
                <div className="text-2xl font-bold text-blue-700">{totals.monatlich.toFixed(2)} €</div>
              </div>
            )}
            {totals.quartalsweise > 0 && (
              <div>
                <div className="text-sm font-medium text-gray-600">Quartalsweise</div>
                <div className="text-2xl font-bold text-gray-700">{totals.quartalsweise.toFixed(2)} €</div>
              </div>
            )}
          </div>
          <div className="flex gap-3">
            <button
              onClick={onPreview}
              className="inline-flex items-center px-4 py-2 border rounded-md text-gray-700 bg-white xtext-white"
            >
              <Eye className="h-4 w-4 mr-2" />
              Vorschau
            </button>
            <button
              onClick={onExportPDF}
              className="inline-flex items-center px-4 py-2 border rounded-md text-gray-700 bg-white xtext-white"
            >
              <FileDown className="h-4 w-4 mr-2" />
              Export PDF
            </button>
            {/*<button
              onClick={onSaveQuote}
              className="inline-flex items-center px-4 py-2 rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <Save className="h-4 w-4 mr-2" />
              Save Quote
            </button>*/}
          </div>
        </div>
      </div>
    </div>
  );
};
